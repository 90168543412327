import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule, TitleStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideHttpClient, withInterceptorsFromDi }    from '@angular/common/http';
// import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import { ReactiveFormsModule } from '@angular/forms';
// import { CalendarModule } from 'ion2-calendar';
import { CustomTitleStrategy } from './custom-title-strategy';
import { SharedComponentModule } from './modules/shared/shared.module';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [IonicModule.forRoot(), BrowserModule, AppRoutingModule, ReactiveFormsModule, RouterModule, SharedComponentModule], providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: TitleStrategy, useClass: CustomTitleStrategy },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
