<ion-app>
  <ion-split-pane contentId="my-content">
    
    
    <ion-menu menuId="my-content" contentId="my-content" side="end" class="my-custom-menu">
      
      <ion-header id="side-navigation" class="ion-no-border">
        <ion-toolbar color="primary">
    
          <div class="logo-container ion-padding" *ngIf="globalService.logo">
            <img class="logo" [src]="globalService.logo"/>
          </div>

        </ion-toolbar>
      </ion-header>
      
      <ion-content >
        <ion-list class="sidebar-menu">
          
          <ion-menu-toggle auto-hide="false">

            <ion-item [routerLink]="['/home']">
              <ion-label>Home</ion-label>
            </ion-item>

            <!-- For  Store Manager -->
            <ion-item-group *ngIf="authService.isLoggedIn && authService.userRole == 'cargo_operator'">

              <ion-item-divider>
                <ion-label>Cargo Operator</ion-label>
              </ion-item-divider>

              <ion-item [routerLink]="['/cargo-operators/services']">
                <ion-label>Cargo Services</ion-label>
              </ion-item>
              <ion-item [routerLink]="['/cargo-requests']">
                <ion-label>Cargo Box Requests</ion-label>
                <ion-badge color="success" *ngIf="authService.statsData && authService.statsData['cargo_operator_requests']">
                  {{ authService.statsData['cargo_operator_requests'] }}
                </ion-badge>
              </ion-item>
              <ion-item>
                <ion-label [routerLink]="['/cargo-boxes']">Cargo Box Pickup Requests</ion-label>
                <ion-badge color="success" 
                  *ngIf="authService.statsData && authService.statsData['cargo_operator_pickup_requests']">
                  {{ authService.statsData['cargo_operator_pickup_requests'] }}
                </ion-badge>
              </ion-item>
              <ion-item [routerLink]="['/cargo-operator-wallet']">
                <ion-label>Cargo Wallet</ion-label>
              </ion-item>
              
            </ion-item-group>


            
            <ion-item-group *ngIf="authService.isLoggedIn && authService.userRole == 'customer'">

              <ion-item-divider>
                <ion-label>Your Account</ion-label>
              </ion-item-divider>

              <ion-item [routerLink]="['/customer-profile']">
                <ion-label>Profile</ion-label>
              </ion-item>
              <ion-item [routerLink]="['/customer-orders']">
                <ion-label>Orders</ion-label>
              </ion-item>

              <ion-item>
                <ion-label [routerLink]="['/cargo-requests']">Cargo Box Requests</ion-label>
                <ion-badge color="success" *ngIf="authService.statsData && authService.statsData['cargo_operator_requests']">
                  {{ authService.statsData['cargo_operator_requests'] }}
                </ion-badge>
              </ion-item>

              <ion-item>
                <ion-label [routerLink]="['/cargo-boxes']">My Cargo Boxes</ion-label>
                <ion-badge color="success" 
                  *ngIf="authService.statsData && authService.statsData['cargo_operator_pickup_requests']">
                  {{ authService.statsData['cargo_operator_pickup_requests'] }}
                </ion-badge>
              </ion-item>

              
              
            </ion-item-group>


            <ion-item-group>
              
              <ion-item-divider>
                <ion-label>Account</ion-label>
              </ion-item-divider>

              
  

              <ion-item [routerDirection]="'root'" *ngIf="!authService.isLoggedIn" [routerLink]="['/login']">
                <ion-label>
                  Hello, Sign in?
                </ion-label>
              </ion-item>
  
          
              <ion-item [routerDirection]="'root'" *ngIf="!authService.isLoggedIn" [routerLink]="['/register']">
                <ion-label>
                  Create an Account
                </ion-label>
              </ion-item>

              <ion-item *ngIf="authService.isLoggedIn" (click)="logout()">
                <ion-label>Logout</ion-label>
              </ion-item>

              <ion-item *ngIf="authService.isLoggedIn" (click)="closeAccount()">
                <ion-label>Close Account</ion-label>
              </ion-item>

              <ion-item [routerLink]="['/support']">
                <ion-label>
                  Need Support?
                </ion-label>
              </ion-item>

            </ion-item-group>

            <!-- For Non Store Manager -->
            <ion-item-group *ngIf="authService.isLoggedIn && authService.userRole == 'customer'">

              <ion-item-divider>
                <ion-label>Partnership</ion-label>
              </ion-item-divider>
            
              <ion-item [routerLink]="['/partnership']">
                <ion-label>Store Merchant</ion-label>
              </ion-item>

              <ion-item [routerLink]="['/cargo-operator-inquiry']">
                <ion-label>Cargo Operator</ion-label>
              </ion-item>
              
            </ion-item-group>


             
            


            <!-- For  Store Manager -->
            <ion-item-group *ngIf="authService.isLoggedIn && authService.userRole == 'store_manager'">

              <ion-item-divider>
                <ion-label>Business</ion-label>
              </ion-item-divider>

              <ion-item [routerLink]="['/store-manager-dashboard']">
                <ion-label>Dashboard</ion-label>
              </ion-item>
              
            </ion-item-group>


            <ion-item-group *ngIf="authService.isLoggedIn && authService.userRole == 'manager'">

              <ion-item-divider>
                <ion-label>Management</ion-label>
              </ion-item-divider>

              <ion-item [routerLink]="['/manager-dashboard']">
                <ion-label>Dashboard</ion-label>
              </ion-item>
         
            </ion-item-group>



            <ion-item-group *ngIf="authService.isLoggedIn && authService.userRole == 'admin'">

              <ion-item-divider>
                <ion-label>Management</ion-label>
              </ion-item-divider>

              <ion-item [routerLink]="['/admin-dashboard']">
                <ion-label>Dashboard</ion-label>
              </ion-item>
         
            </ion-item-group>


            <ion-item-group *ngIf="authService.isLoggedIn && authService.userRole == 'rider'">

              <ion-item-divider>
                <ion-label>Management</ion-label>
              </ion-item-divider>

              <ion-item [routerLink]="['/delivery-rider-dashboard']">
                <ion-label>Dashboard</ion-label>
              </ion-item>
              
            </ion-item-group>




          </ion-menu-toggle>

         


        </ion-list>

        <p class="ion-text-center version-text" *ngIf="globalService">
          QuickMonkeyDelivery App | {{ globalService.getCurrentAppVersion() }}
        </p>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="my-content"></ion-router-outlet>
    

  </ion-split-pane>
</ion-app>