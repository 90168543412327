
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { User } from '../models/user';
import { StoreCategory } from '../models/store-category';
import { StorageService } from './storage.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { Store, StoreItem } from '../models/store';
import { Order } from '../models/order';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  setting: any;
  testing = false;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private globalService: GlobalService,
    private authService: AuthService,
    private env: EnvService,
  ) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  getSetting(){

    // const headers = new HttpHeaders({
    //   'Authorization': "Bearer " + this.authService.token,
    // });

    return this.http.get(this.env.API_URL + 'setting',{}).pipe(
      tap(data => {
        this.setting = data;
        this.globalService.currencySymbol = this.setting.currencySymbol;
      })
    );
  }

  updateSetting() {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.post(this.env.API_URL + 'setting',this.setting,{ headers: headers }).pipe(
      tap(data => {
        //
      })
    );

  }
}
