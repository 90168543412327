<ion-footer>
  <ion-toolbar color="light" id="main-footer-tabs">
      <ion-row>
        
        <!-- <ion-col size="3">
          <div (click)="navigate('/landing')" class="footer-tab ion-text-center">
            <ion-icon name="locate"></ion-icon>
          </div>
        </ion-col> -->

        <ion-col size="4" >
          <div (click)="navigate('/home')" class="footer-tab ion-text-center">
            <ion-icon name="home"></ion-icon>
            <span>Home</span>
          </div>
        </ion-col>

        <ion-col size="4" >
          <div (click)="navigate('/cart')" class="footer-tab ion-text-center">
            <ion-icon name="cart"></ion-icon>
            <span>Cart</span>
          </div>
        </ion-col>

        <ion-col size="4">
          <div class="footer-tab ion-text-center" (click)="openCustom()"><ion-icon name="person-circle"></ion-icon><span>Menu</span></div>
          
        </ion-col>
        
      </ion-row>
  </ion-toolbar>
</ion-footer>


