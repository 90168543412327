import { Injectable } from '@angular/core';
import { Order } from '../models/order';
import { OrderItem } from '../models/order-item';
import { Store, StoreItem } from '../models/store';
import { StorageService } from './storage.service';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';;
import { tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { User } from '../models/user';
import { StoreCategory } from '../models/store-category';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { NgForm } from '@angular/forms';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  
  orderStoreDiscount = false;

  orderStore: any;
  order: Order = null;
  orderItems: OrderItem[] = null;
  orderItemsCount: number = 0;

  totalPrice: number = 0;
  totalPriceOriginal: number = 0;

  deliveryFee: number = 0;
  deliveryFeeMaxKM: number = 0;
  deliveryAdditionalCharge: number = 0;
  serviceFeeRate: number = 0;

  // Orders
  token:any;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private globalService: GlobalService,
    private authService: AuthService,
    private env: EnvService,
  ) { }

  getServiceFee(){
    let total = (this.totalPrice * (this.serviceFeeRate / 100));
    total = parseFloat(total.toFixed(2).toString());
    return total;
  }

  getDeliveryFee(){

    let deliveryFee = parseFloat(this.deliveryFee.toString());

    if (this.orderStore && this.orderStore.store_delivery_fixed_charge_status == 'yes'){
      // Store Custom Delivery Charge
      deliveryFee = parseFloat(this.orderStore.store_delivery_fixed_charge_rate.toString());
      if (this.orderStore.distance && this.orderStore.distance >= this.orderStore.store_delivery_fixed_charge_km){
        // this.deliveryFee+=50;
        const excess = this.orderStore.distance - this.orderStore.store_delivery_fixed_charge_km;
        const additionalDeliveryCharges = excess * this.orderStore.store_delivery_fixed_charge_additional;
        deliveryFee+=additionalDeliveryCharges;
      }

    }else{
      // Admin Setting Page
      deliveryFee = parseFloat(this.deliveryFee.toString());
      if (this.orderStore.distance && this.orderStore.distance >= this.deliveryFeeMaxKM){
        // this.deliveryFee+=50;
        const excess = this.orderStore.distance - this.deliveryFeeMaxKM;
        const additionalDeliveryCharges = excess * this.deliveryAdditionalCharge;
        deliveryFee+=additionalDeliveryCharges;
      }
    }
    

    

    return deliveryFee.toFixed(2);
  }

  getCartTotal(){
    let total = (this.totalPrice + this.getServiceFee() + parseFloat(this.getDeliveryFee().toString()));
    total = parseFloat(total.toFixed(2).toString());
    return total;
  }

  getDiscountTotal(){
    let total = (parseFloat(this.totalPriceOriginal.toString()) - parseFloat(this.totalPrice.toString()));
    total = parseFloat(total.toFixed(2).toString());
    return total;
  }

  formatMoney(value){
    let total = parseFloat(value.toString())
    let newValue = total.toFixed(2); //parseFloat(total.toFixed(2).toString());
    return newValue;
  }

  getItemQuantity(itemID){
    let sameVariant = this.order.items.filter(orderItem => orderItem.store_item_id == itemID);

    
    if (sameVariant[0] && sameVariant[0].quantity > 0){
      console.log('item qty',sameVariant[0]);
      return sameVariant[0].quantity;
    }
    return;
    
  }
  itemQuantity(item: StoreItem, type: string){

    let sameVariant = this.order.items.filter(orderItem => orderItem.store_item_id == item.id);
        
    switch (type){
      case 'add':
        
        if (sameVariant.length) {
          sameVariant[0].quantity = sameVariant[0].quantity + 1;
        } else {
          let orderItem: OrderItem = {
            store_item_id: item.id,
            store_item_name: item.item_name,
            price: parseFloat(item.item_regular_price.toString()),
            item_regular_price: parseFloat(item.item_regular_price.toString()),
            quantity: 1,
          };
          this.order.items.push(orderItem);
        }
        
      break;

      case 'substract':
        if (sameVariant.length) {
          sameVariant[0].quantity = sameVariant[0].quantity - 1;
          
          if (sameVariant[0].quantity == 0){
            let index = this.order.items.indexOf(sameVariant[0]);
            this.order.items.splice(index, 1);
          }

        }
      break;
    }
    
    this.recompute();
    this.saveSession();
    

  }



  itemQuantityCart(itemID, type: string){

    let sameVariant = this.order.items.filter(orderItem => orderItem.store_item_id == itemID);
        
    switch (type){
      case 'add':
        
        if (sameVariant.length) {
          sameVariant[0].quantity = sameVariant[0].quantity + 1;
        }
        
      break;

      case 'substract':
        if (sameVariant.length) {
          sameVariant[0].quantity = sameVariant[0].quantity - 1;
          
          if (sameVariant[0].quantity == 0){
            let index = this.order.items.indexOf(sameVariant[0]);
            this.order.items.splice(index, 1);
          }

        }
      break;
    }
    
    this.recompute();
    this.saveSession();
    

  }

  saveSession(){
    console.log("Saving Order Session",this.order);
    // Save Session
    this.storageService.set('order', JSON.stringify(this.order) ).then(
      () => {
        console.log('Order Stored');
      },
      error => console.error('Error Storing Order', error)
    );
  }

  removeSession(){
    this.storageService.remove('order');
  }

  recompute(){

    console.log('this.order.items',this.order.items);
    
    if (this.order && this.order.items){
      // 👇️ [5, 15, 45]
      const values = Object.values(this.order.items);

      this.orderItemsCount = values.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);


      this.totalPrice = 0;
      for (let i = 0; i < this.order.items.length; i++) {
        this.totalPrice+=  this.order.items[i].quantity * this.order.items[i].price;
      }
    }
    
    // Check Order Store Discount
    this.orderStoreDiscount = false;
    this.totalPriceOriginal = this.totalPrice;
    this.order.items_total_original = this.totalPriceOriginal;

    if (this.orderStore && this.orderStore.discount_status_bool){
      if (this.totalPrice >= this.orderStore.discount_min_cart_total){
        this.orderStoreDiscount = true;

        switch (this.orderStore.discount_type){
          case 'percentage':
            this.totalPrice = this.totalPriceOriginal - (this.totalPriceOriginal * (this.orderStore.discount_value / 100));
          break;
          case 'fixed':
            this.totalPrice = this.totalPriceOriginal - this.orderStore.discount_value;
          break;
        }

      }
    }

      
    
    // console.log('Order Items',this.order);
    // console.log('Order Items stringify',JSON.stringify(this.order));
  }

  // Orders API
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };



  getOrdersSummaryByCustomer(): Observable<any[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<any[]>(this.env.API_URL + 'customer/orders/summary',{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  getOrdersByCustomer(): Observable<Order[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<Order[]>(this.env.API_URL + 'customer/orders',{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }



  getOrdersByRiderStatus(status): Observable<Order[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<Order[]>(this.env.API_URL + 'rider/orders/status/' + status,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  getOrdersSummaryByRider(): Observable<any[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<any[]>(this.env.API_URL + 'rider/orders/summary',{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }
  getOrdersByRider(): Observable<Order[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<Order[]>(this.env.API_URL + 'rider/orders',{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

  getItemPrice(item){
    let price = item.item_regular_price;
    return price;
  }
  getSaleItemPrice(item){

    // console.log(item);
    let price = item.item_regular_price;

    // console.log('price',item);

    if 
      (this.orderStore && this.orderStore.discount_status_bool && 
      this.orderStore.discount_value && 
      this.orderStore.discount_type == 'percentage' && 
      this.orderStoreDiscount
    ){
      price = (this.orderStore.discount_value / 100) * item.item_regular_price;
      price = price.toFixed(2);
      return price;
    }
    return price;
  }

  deleteOrder(id): Observable<Order[]> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.authService.token
    });

    return this.http.get<any>(this.env.API_URL + 'delete-order/' + id,{ headers: headers })
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

}
