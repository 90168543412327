import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { initialize } from '@ionic/core';
import { Store } from '../models/store';
import { StoreCategory } from '../models/store-category';
import { AuthService } from './auth.service';
import { StoreService } from './store.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // Version
  appPlatform = 'web'; // web, android or ios
  versionWeb = 0.02;
  versionAndroid = 0.02;
  versionIOS = 0.02;

  token: string = '';
  currencySymbol: string = '';
  user: any;

  changeAddress: boolean = false;

  firstName: string = '';
  lastName: string = '';
  address: string = '';
  addressInformation: string = '';

  logo:string = 'https://manage.quickmonkeydelivery.com/QMD-Logo.png';
  
  // Store
  store: Store;
  storeID: string;

  storeCategoriesHomePage: StoreCategory[] = [];
  storeCategories: StoreCategory[] = [];
  storesBestSellers: Store[] = [];
  storesBestSellersTwo: Store[] = [];
  storesFeatured1: Store[] = [];
  storesFeatured2: Store[] = [];
  storesFeatured3: Store[] = [];

  storesByGroupIDS: any = [1,2,3];
  storesByGroup: any;

  loading: any;
  
  constructor(
    private router: Router,
    private storeService: StoreService,
    private authService: AuthService,
    private loadingCtrl: LoadingController
  ) { }


  getCurrentAppVersion(){
    
      let versionText;

      switch (this.appPlatform){
        case 'web':
            versionText = 'Web Version ' + this.versionWeb;
        break;
        case 'android':
            versionText = 'Android Version ' + this.versionAndroid;
        break;
        case 'ios':
            versionText = 'IOS Version ' + this.versionIOS;
        break;
      }


      return versionText;
  }

  initApp(){
    this.authService.user().subscribe(
      data => {
        // console.log('data',data);
        this.user = data;
        this.addressInformation = data.address;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
      },
      error => {
        this.getStoreCategories();
        this.getStores();
      },
      () => {
        this.getStoreCategories();
        this.getStores();
      }
    );
  }
  redirectToPage(url){
    this.router.navigateByUrl(url);
  }


  getStoreHomePageCategories() {

    //Get saved list of students
    this.storeService.getStoreCategories('?homepage_categories=yes').subscribe(storeCategories => {
      this.storeCategoriesHomePage = storeCategories.data;

      // this.storeCategories.forEach(function(category){
      //   this.storeService.getStoresByCategory(category.id).subscribe(storeCategories => {
      //     this.storesByGroup.push(storeCategories.data);
      //   });
      //   console.log('this.storesByGroup',this.storesByGroup);
      // });
      
    })
  }


  getStoreCategories() {

    //Get saved list of students
    this.storeService.getStoreCategories().subscribe(storeCategories => {
      this.storeCategories = storeCategories.data;

      // this.storeCategories.forEach(function(category){
      //   this.storeService.getStoresByCategory(category.id).subscribe(storeCategories => {
      //     this.storesByGroup.push(storeCategories.data);
      //   });
      //   console.log('this.storesByGroup',this.storesByGroup);
      // });
      
    })
  }
  getBestSellerStores() {
    //Get saved list of students
    this.storeService.getStoresByCategory(3).subscribe(storeCategories => {
      this.storesBestSellers = storeCategories.data;
      this.storesBestSellersTwo = storeCategories.data;
    })
  }

  getFeaturedStores() {
    //Get saved list of students
    this.storeService.getStoresByCategory(6).subscribe(storeCategories => {
      this.storesFeatured1 = storeCategories.data;
    })
  }

  getFeatured2Stores() { 
    //Get saved list of students
    this.storeService.getStoresByCategory(7).subscribe(storeCategories => {
      this.storesFeatured2 = storeCategories.data;
    })
  }
  getFeatured3Stores() { 
    //Get saved list of students
    this.storeService.getStoresByCategory(8).subscribe(storeCategories => {
      this.storesFeatured2 = storeCategories.data;
    })
  }

  getStores(){
    this.getBestSellerStores();
    // this.getFeaturedStores();
    // this.getFeatured2Stores();
    // this.getFeatured3Stores();
  }

  showLoading(message = 'Loading...') {
    this.loadingCtrl.create({
      message
    }).then((response) => {
        response.present();
    });
    }

  dismissLoading(){
    this.loadingCtrl.dismiss().then((response) => {
        // console.log('Loader closed!', response);
    }).catch((err) => {
        console.log('Error occured : ', err);
    });
  }
}
