import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async set(key: string, value: any): Promise<void> {
    await Preferences.set({
      key: key,
      value: value,
    });
  }
  async get(key: string): Promise<any> {
    const { value } = await Preferences.get({ key: key });

    // console.log(`Hello ${value}!`);
    return value;
  }
  async remove(key: string): Promise<void> {
    await Preferences.remove({ key: key });
  }
}



