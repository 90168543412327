import { Component } from '@angular/core';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { AlertService } from './services/alert.service'
import { SplashScreen, SplashScreenPlugin } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { StorageService } from './services/storage.service';
import { OrderService } from './services/order.service';
import { Geolocation } from '@capacitor/geolocation';
import { GlobalService } from './services/global.service';
import OneSignal from 'onesignal-cordova-plugin';
import { Router } from '@angular/router';
import { SettingService } from './services/setting.service';

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';

// import { register } from 'swiper/element/bundle';

// register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public customerPages = [
    // {
    //   title: 'Profile',
    //   url: '/profile',
    //   icon: 'person-circle'
    // },
    // {
    //   title: 'Addressess',
    //   url: '/home',
    //   icon: 'home'
    // },
    // {
    //   title: 'Manage Orders',
    //   url: '/list',
    //   icon: 'list'
    // },
    {
      title: 'Partnership',
      url: '/partnership',
      icon: 'bag-handle'
    },
    {
      title: 'Contact Us',
      url: '/list',
      icon: 'list'
    }
  ];


  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'home'
    },
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    },
  ];
  constructor(
    private platform: Platform,
    public authService: AuthService,
    private navCtrl: NavController,
    private alertService: AlertService,
    private storageService: StorageService,
    public orderService: OrderService,
    public globalService: GlobalService,
    public settingService: SettingService,
    private router: Router,
    private alertController: AlertController
  ) {


    this.initializeApp();

    this.settingService.getSetting().subscribe(response => {
      this.globalService.showLoading('Preparing the app...'); // show loading
      
      setTimeout(() => {
        this.globalService.dismissLoading(); // dismiss
      }, 2500);
    });

    this.storageService.get('address').then(
      data => {
        if (data){
          this.globalService.address = data;
        }
      }
    );

   
    // const printCurrentPosition = async () => {
    //   const coordinates = await Geolocation.getCurrentPosition();
    //   alert('coordinates: ' + JSON.stringify(coordinates));
    //   console.log('Current position:', coordinates);
    // };

    // navigator.geolocation.getCurrentPosition(function(location){
    //   alert('latitude: ' + JSON.stringify(location.coords.latitude));
    //   alert('longitude: ' + JSON.stringify(location.coords.longitude));
    // },function(){
    //     alert('User not allowed')
    // },{timeout:10000})

    this.authService.updateUserLocation();


  }

  
  initializeApp() {

    // SplashScreen.show({
    //   showDuration: 6000,
    //   autoHide: false,
    // });

     this.platform.ready().then(() => {
      
      if (this.platform.is('hybrid')){
        this.OneSignalInit();
      }
      
      SplashScreen.hide();

      if (Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.setBackgroundColor({color:'#ff470c'});
        StatusBar.show();
      }
      if (this.platform.is('ios')){
        this.requestPermission();
      }
     
      
    });

    console.log('Initialize App');
    
    this.authService.getToken();
    
    // Init User
    // this.authService.user().subscribe(
    //   data => {
    //     console.log('data',data);
    //     this.alertService.presentToast("Welcome back to QMD!");
    //   },
    //   error => {
    //     this.storageService.remove("token");
    //     this.authService.isLoggedIn = false;
    //     this.navCtrl.navigateRoot('/landing');
    //   },
    //   () => {
    //     // this.dismissLogin();
    //     // this.navCtrl.navigateRoot('/landing');
    //   }
    // );
    
    
  }

  profile(){

  }


  // Call this function when your app starts
OneSignalInit(): void {
  // Uncomment to set OneSignal device logging to VERBOSE  
  // OneSignal.setLogLevel(6, 0);

  let router = this.router;

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  OneSignal.setAppId("5812e331-390e-4611-a94f-b41ddfee5b86");
  OneSignal.setNotificationOpenedHandler(function(jsonData) {
    let additionalData = jsonData['notification']['additionalData'];
    let action = additionalData['action'];
    if (action == 'navigate'){
      let url = additionalData['url'];
      router.navigateByUrl(url);
    }
    
    // alert('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    // alert('notificationOpenedCallback: ' + JSON.stringify(additionalData));
  });

  // Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
  });


  


}


  partnership(){

  }

  logout() {
    this.authService.logout().subscribe(
      data => {
        this.alertService.presentToast('Successfully logout!');        
      },
      error => {
        console.log(error);
      },
      () => {
        this.navCtrl.navigateRoot('/');
      }
    );
  }
  
  closeAccount(){

    this.alertController.create({
      header: 'INFORMATION',
      // subHeader: 'Confirmation',
      message: 'Are you sure? We will remove your account from our system.',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            console.log('Let me think');
          }
        },
        {
          text: 'Confirm',
          handler: () => {
            this.authService.closeAccount().subscribe(
              data => {
                this.alertService.presentToast('Successfully closed your account!');        
              },
              error => {
                console.log(error);
              },
              () => {
                this.navCtrl.navigateRoot('/');
              }
            );
          }
        }
      ]
    }).then(res => {
      res.present();
    });
    
  }


  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();
  
    console.log(response);
    // { status: 'authorized' } for example
  
    return response;
  }
  
  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();
  
    console.log(response);
    // { status: 'authorized' } for example
    this.globalService.dismissLoading(); // dismiss
  
    return response;
  }
}
