import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EnvService {
  
  API_URL = 'https://manage.quickmonkeydelivery.com/api/';
  GOOGLE_API_KEY = 'AIzaSyBFKzjmP3j5Ju380vFywuWkl9pNnLjVSd0';
  // API_URL = 'https://qmd.test/api/';
  
  constructor() { }
}