import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreSliderDetailComponent } from 'src/app/components/store-slider-detail/store-slider-detail.component';
import { FooterTabsComponent } from 'src/app/components/footer-tabs/footer-tabs.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [StoreSliderDetailComponent,FooterTabsComponent],
  imports: [
  CommonModule,IonicModule
  ],
  exports: [StoreSliderDetailComponent,FooterTabsComponent],
  })

  export class SharedComponentModule { }
