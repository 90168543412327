<div *ngIf="store" class="store-container" (click)="navigate()">

  <div class="image">
    <ion-img *ngIf="store.store_image" [src]="store.store_image"></ion-img>
    
  </div>
  <div class="content">
    <div class="title">
      <h3>{{ store.store_name }}</h3>
    </div>
    
    <div class="distance">
      <h4> <ion-icon name="navigate"></ion-icon>{{ store.distance }} kms away</h4>
    </div>
    <div class="promotion-discount-note-text-container" *ngIf="store && store.discount_status_bool">
      {{ store.discount_note_text }}
    </div>

    <div class="store-open-status" [class]="storeBusinessStatus" *ngIf="store.store_business_hours">
      {{ storeBusinessStatus }}
    </div>
    
  </div>
</div>