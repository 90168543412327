import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-footer-tabs',
  templateUrl: './footer-tabs.component.html',
  styleUrls: ['./footer-tabs.component.scss'],
})
export class FooterTabsComponent implements OnInit {

  constructor(
    private router: Router,
    private menu: MenuController,
    public authService: AuthService
  ) { }

  ngOnInit() {}

  navigate(pageUrl){
    this.router.navigate([pageUrl]);
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    // alert('test');
    this.menu.enable(true, 'my-content');
    this.menu.open('my-content');

    
  }

}
