import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  // { path: '', redirectTo: '/landing', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'store/:id',
    loadChildren: () => import('./pages/store/store.module').then( m => m.StorePageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store.module').then( m => m.StorePageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'category/:id',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'stores',
    loadChildren: () => import('./pages/stores/stores.module').then( m => m.StoresPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'thank-you',
    loadChildren: () => import('./pages/thank-you/thank-you.module').then( m => m.ThankYouPageModule)
  },
  {
    path: 'customer-dashboard',
    loadChildren: () => import('./pages/customer/customer-dashboard/customer-dashboard.module').then( m => m.CustomerDashboardPageModule)
  },
  {
    path: 'customer-profile',
    loadChildren: () => import('./pages/customer/customer-profile/customer-profile.module').then( m => m.CustomerProfilePageModule)
  },
  {
    path: 'customer-orders',
    loadChildren: () => import('./pages/customer/customer-orders/customer-orders.module').then( m => m.CustomerOrdersPageModule)
  },
  {
    path: 'customer-addresses',
    loadChildren: () => import('./pages/customer/customer-addresses/customer-addresses.module').then( m => m.CustomerAddressesPageModule)
  },
  {
    path: 'customer-order',
    loadChildren: () => import('./pages/customer-order/customer-order.module').then( m => m.CustomerOrderPageModule)
  },
  {
    path: 'customer-order/:id',
    loadChildren: () => import('./pages/customer-order/customer-order.module').then( m => m.CustomerOrderPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'store-manager-dashboard',
    loadChildren: () => import('./pages/store-manager/store-manager-dashboard/store-manager-dashboard.module').then( m => m.StoreManagerDashboardPageModule)
  },
  {
    path: 'store-manager-orders',
    loadChildren: () => import('./pages/store-manager/store-manager-orders/store-manager-orders.module').then( m => m.StoreManagerOrdersPageModule)
  },
  {
    path: 'store-manager-items',
    loadChildren: () => import('./pages/store-manager/store-manager-items/store-manager-items.module').then( m => m.StoreManagerItemsPageModule)
  },
  {
    path: 'delivery-rider-dashboard',
    loadChildren: () => import('./pages/delivery-rider/delivery-rider-dashboard/delivery-rider-dashboard.module').then( m => m.DeliveryRiderDashboardPageModule)
  },
  {
    path: 'delivery-rider-orders',
    loadChildren: () => import('./pages/delivery-rider/delivery-rider-orders/delivery-rider-orders.module').then( m => m.DeliveryRiderOrdersPageModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/admin/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule)
  },
  {
    path: 'admin-customers',
    loadChildren: () => import('./pages/admin/admin-customers/admin-customers.module').then( m => m.AdminCustomersPageModule)
  },
  {
    path: 'admin-orders',
    loadChildren: () => import('./pages/admin/admin-orders/admin-orders.module').then( m => m.AdminOrdersPageModule)
  },
  {
    path: 'admin-stores',
    loadChildren: () => import('./pages/admin/admin-stores/admin-stores.module').then( m => m.AdminStoresPageModule)
  },
  {
    path: 'admin-items',
    loadChildren: () => import('./pages/admin/admin-items/admin-items.module').then( m => m.AdminItemsPageModule)
  },
  {
    path: 'admin-delivery-riders',
    loadChildren: () => import('./pages/admin/admin-delivery-riders/admin-delivery-riders.module').then( m => m.AdminDeliveryRidersPageModule)
  },
  {
    path: 'admin-app-setting',
    loadChildren: () => import('./pages/admin/admin-app-setting/admin-app-setting.module').then( m => m.AdminAppSettingPageModule)
  },
  {
    path: 'admin-reports',
    loadChildren: () => import('./pages/admin/admin-reports/admin-reports.module').then( m => m.AdminReportsPageModule)

  },
  {
    path: 'partnership',
    loadChildren: () => import('./pages/partnership/partnership.module').then( m => m.PartnershipPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'store-manager-settings',
    loadChildren: () => import('./pages/store-manager/store-manager-settings/store-manager-settings.module').then( m => m.StoreManagerSettingsPageModule)
  },
  {
    path: 'store-manager-item-add',
    loadChildren: () => import('./pages/store-manager/store-manager-item-add/store-manager-item-add.module').then( m => m.StoreManagerItemAddPageModule)
  },
  {
    path: 'store-manager-item-show/:id',
    loadChildren: () => import('./pages/store-manager/store-manager-item-show/store-manager-item-show.module').then( m => m.StoreManagerItemShowPageModule)
  },
  {
    path: 'store-manager-order/:id',
    loadChildren: () => import('./pages/store-manager/store-manager-order/store-manager-order.module').then( m => m.StoreManagerOrderPageModule)
  },
  {
    path: 'store-manager-order-status/:status',
    loadChildren: () => import('./pages/store-manager/store-manager-order-status/store-manager-order-status.module').then( m => m.StoreManagerOrderStatusPageModule)
  },
  {
    path: 'admin-order-status/:status',
    loadChildren: () => import('./pages/admin/admin-order-status/admin-order-status.module').then( m => m.AdminOrderStatusPageModule)
  },
  {
    path: 'admin-order/:id',
    loadChildren: () => import('./pages/admin/admin-order/admin-order.module').then( m => m.AdminOrderPageModule)
  },
  {
    path: 'delivery-rider-order-status/:status',
    loadChildren: () => import('./pages/delivery-rider/delivery-rider-order-status/delivery-rider-order-status.module').then( m => m.DeliveryRiderOrderStatusPageModule)
  },
  {
    path: 'delivery-rider-order/:id',
    loadChildren: () => import('./pages/delivery-rider/delivery-rider-order/delivery-rider-order.module').then( m => m.DeliveryRiderOrderPageModule)
  },
  {
    path: 'admin-store/:id',
    loadChildren: () => import('./pages/admin/admin-store/admin-store.module').then( m => m.AdminStorePageModule)
  },
  {
    path: 'admin-store-categories',
    loadChildren: () => import('./pages/admin/admin-store-categories/admin-store-categories.module').then( m => m.AdminStoreCategoriesPageModule)
  },
  {
    path: 'admin-store-category/:id',
    loadChildren: () => import('./pages/admin/admin-store-category/admin-store-category.module').then( m => m.AdminStoreCategoryPageModule)
  },
  {
    path: 'admin-store-category-add',
    loadChildren: () => import('./pages/admin/admin-store-category-add/admin-store-category-add.module').then( m => m.AdminStoreCategoryAddPageModule)
  },
  {
    path: 'geolocator/:model/:id',
    loadChildren: () => import('./pages/geolocator/geolocator.module').then( m => m.GeolocatorPageModule)
  },
  {
    path: 'store-manager-item-categories-index',
    loadChildren: () => import('./pages/store-manager/store-manager-item-categories-index/store-manager-item-categories-index.module').then( m => m.StoreManagerItemCategoriesIndexPageModule)
  },
  {
    path: 'store-manager-item-categories-create',
    loadChildren: () => import('./pages/store-manager/store-manager-item-categories-create/store-manager-item-categories-create.module').then( m => m.StoreManagerItemCategoriesCreatePageModule)
  },
  {
    path: 'store-manager-item-categories-show/:id',
    loadChildren: () => import('./pages/store-manager/store-manager-item-categories-show/store-manager-item-categories-show.module').then( m => m.StoreManagerItemCategoriesShowPageModule)
  },
  {
    path: 'admin-notifications-index',
    loadChildren: () => import('./pages/admin/admin-notifications-index/admin-notifications-index.module').then( m => m.AdminNotificationsIndexPageModule)
  },
  {
    path: 'admin-notifications-create',
    loadChildren: () => import('./pages/admin/admin-notifications-create/admin-notifications-create.module').then( m => m.AdminNotificationsCreatePageModule)
  },
  {
    path: 'admin-notifications-edit/:id',
    loadChildren: () => import('./pages/admin/admin-notifications-edit/admin-notifications-edit.module').then( m => m.AdminNotificationsEditPageModule)
  },
  {
    path: 'admin-notifications-show/:id',
    loadChildren: () => import('./pages/admin/admin-notifications-show/admin-notifications-show.module').then( m => m.AdminNotificationsShowPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'admin-customer-edit/:id',
    loadChildren: () => import('./pages/admin/admin-customer-edit/admin-customer-edit.module').then( m => m.AdminCustomerEditPageModule)
  },
  {
    path: 'admin-setting',
    loadChildren: () => import('./pages/admin-setting/admin-setting.module').then( m => m.AdminSettingPageModule)
  },
  {
    path: 'store-manager-wallet',
    loadChildren: () => import('./pages/store-manager/store-manager-wallet/store-manager-wallet.module').then( m => m.StoreManagerWalletPageModule)
  },
  {
    path: 'admin-manager-wallet',
    loadChildren: () => import('./pages/admin/admin-manager-wallet/admin-manager-wallet.module').then( m => m.AdminManagerWalletPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'pickup-and-drop',
    loadChildren: () => import('./pages/pickup-and-drop/pickup-and-drop.module').then( m => m.PickupAndDropPageModule)
  },
  {
    path: 'admin-report-orders',
    loadChildren: () => import('./admin/admin-report-orders/admin-report-orders.module').then( m => m.AdminReportOrdersPageModule)
  },
  {
    path: 'manager-dashboard',
    loadChildren: () => import('./manager-dashboard/manager-dashboard.module').then( m => m.ManagerDashboardPageModule)
  },
  {
    path: 'special-deals',
    loadChildren: () => import('./special-deals/special-deals.module').then( m => m.SpecialDealsPageModule)
  },
  {
    path: 'cargo-operator-inquiry',
    loadChildren: () => import('./pages/cargo-operator-inquiry/cargo-operator-inquiry.module').then( m => m.CargoOperatorInquiryPageModule)
  },
  {
    path: 'admin-cargo-operators',
    loadChildren: () => import('./pages/admin/admin-cargo-operators/admin-cargo-operators.module').then( m => m.AdminCargoOperatorsPageModule)
  },
  {
    path: 'admin-cargo-operators/:id/services',
    loadChildren: () => import('./pages/admin/admin-cargo-operators-services/admin-cargo-operators-services.module').then( m => m.AdminCargoOperatorsServicesPageModule)
  },
  {
    path: 'cargo-operators/services',
    loadChildren: () => import('./pages/cargo-operator/cargo-operators-services/cargo-operators-services.module').then( m => m.CargoOperatorsServicesPageModule)
  },
  {
    path: 'cargo-operators',
    loadChildren: () => import('./pages/cargo-operators/cargo-operators.module').then( m => m.CargoOperatorsPageModule)
  },
  {
    path: 'cargo-requests',
    loadChildren: () => import('./pages/customer/customer-cargo-requests/customer-cargo-requests.module').then( m => m.CustomerCargoRequestsPageModule)
  },
  {
    path: 'cargo-boxes',
    loadChildren: () => import('./customer/customer-cargo-boxes/customer-cargo-boxes.module').then( m => m.CustomerCargoBoxesPageModule)
  },
  {
    path: 'cargo-operator-wallet',
    loadChildren: () => import('./pages/cargo-operator/cargo-operator-wallet/cargo-operator-wallet.module').then( m => m.CargoOperatorWalletPageModule)
  },
  {
    path: 'admin-cargo-operator-wallet',
    loadChildren: () => import('./pages/admin/admin-cargo-operator-wallet/admin-cargo-operator-wallet.module').then( m => m.AdminCargoOperatorWalletPageModule)
  },
  {
    path: 'admin-cargo-operator-request',
    loadChildren: () => import('./pages/admin/admin-cargo-operator-request/admin-cargo-operator-request.module').then( m => m.AdminCargoOperatorRequestPageModule)
  },
  {
    path: 'admin-cargo-operator-request-pickup',
    loadChildren: () => import('./pages/admin/admin-cargo-operator-request-pickup/admin-cargo-operator-request-pickup.module').then( m => m.AdminCargoOperatorRequestPickupPageModule)
  }
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
