import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { User } from '../models/user';
import { StorageService } from './storage.service';
import { GlobalService } from './global.service';
import { computeStackId } from '@ionic/angular/directives/navigation/stack-utils';
import { NgForm } from '@angular/forms';
import { AlertService } from './alert.service';
import OneSignal from 'onesignal-cordova-plugin';
import { AlertController, Platform } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isLoggedIn = false;
  token:any;
  
  
  userID: number;
  userEmail: string;
  isStoreManager: boolean = false;
  storeID: number;

  userRole: string = 'customer';

  deliveryRiders: any = [];

  users:any;
  
  userData: any;

  checkNotAcknowledgeOrdersInterval: any;
  checkNotAcknowledgeOrders = 0;
  checkNotAcknowledgeOrdersISOpen = false;
  checkNotAcknowledgeOrdersAlert:any;

  curr_playing_file:any;
  audio = new Audio();

  cargoManagers:any;
  
  constructor(
    private platform: Platform,
    private http: HttpClient,
    private storageService: StorageService,
    private env: EnvService,
    private alertService: AlertService,
    private alertController: AlertController,
    private router: Router
  ) {
    this.audio.src = "https://manage.quickmonkeydelivery.com/alert.wav";
    this.audio.load();
  }


  
  login(email: String, password: String) {
    return this.http.post<any>(this.env.API_URL + 'login',{email: email, password: password}
    ).pipe(
      tap(response => {

        if (response.data.error){
          alert('Invalid credentials');
        }else{
          let token = response.data.token;
          this.storageService.set('token', token).then(
            () => {
              console.log('Token Stored');
            },
            error => console.error('Error storing item', error)
          );
          this.token = token;
          this.isLoggedIn = true;
          return token;
        }
        
      }),
    );
  }

  register(name: String, email: String, password: String, confirmPassword: String) {
    return this.http.post(this.env.API_URL + 'register',
      {name: name, email: email, password: password, confirm_password: confirmPassword}
    )
  }

  logout() {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.get(this.env.API_URL + 'logout', { headers: headers })
    .pipe(
      tap(data => {
        this.storageService.remove("token");
        this.isLoggedIn = false;
        delete this.token;
        return data;
      })
    )
  }

  closeAccount() {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.get(this.env.API_URL + 'close-account', { headers: headers })
    .pipe(
      tap(data => {
        this.storageService.remove("token");
        this.isLoggedIn = false;
        delete this.token;
        return data;
      })
    )
  }


  getDeliveryRiders(){

    console.log('getDeliveryRiders API');

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.get<any>(this.env.API_URL + 'delivery-riders', { headers: headers })
    .pipe(
      tap(data => {
        
        // alert('test');
        // console.log('deliveryRiders',data);
        // this.deliveryRiders = data['delivery_riders'];
        return data;
      })
    )
    
  }

  user(){


    // this.storageService.get("token").then((token) => {
    //     this.token =  token;
    //   },
    //   error => console.error('Error storing item', error)
    // );

    // this.token = this.token;

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.get<any>(this.env.API_URL + 'user', { headers: headers })
    .pipe(
      tap(user => {
        
        this.userData = user;
        this.isLoggedIn =true;
        this.userID = user.id;
        this.userEmail = user.email;
        this.platform.ready().then(() => {

          if (this.platform.is('hybrid')){
            OneSignal.setExternalUserId(this.userID.toString());
          }
          
        });

        this.userRole = user.user_role;
        
        if (user.store_manager){
          this.isStoreManager = true;
          this.storeID = user.store_id;
          this.userRole = user.user_role;

          clearInterval(this.checkNotAcknowledgeOrdersInterval);
          this.runNotAcknowledgeOrders();
        }



        
        
         // Update User Location
        // console.log('// Update User Location');
        this.updateUserLocation();

        return user;
      })
    )
    
    
  }

  runNotAcknowledgeOrders(){
 
    this.checkNotAcknowledgeOrdersInterval = setInterval(() => {

      if (this.checkNotAcknowledgeOrdersAlert){
        this.checkNotAcknowledgeOrdersAlert.dismiss();
      }

      this.getNotAcknoledgeOrders().subscribe( response => {
        

        let totalNonAck = parseInt(response);
        console.warn('total non ack',totalNonAck);
        if (totalNonAck > 0){
          
          this.presentAlertNonAck(totalNonAck);
          
          
          this.audio.play();

        }

        
      });

    },120000);
  }

  async presentAlertNonAck(totalNonAck) {

    this.checkNotAcknowledgeOrdersAlert = await this.alertController.create({
      header: 'You have ' + totalNonAck + ' pending orders.',
      buttons:[{
        text: 'View Orders',
        handler: data =>{
          
          
          this.router.navigateByUrl('/store-manager-orders');

        }
      }]
    });

    await this.checkNotAcknowledgeOrdersAlert.present();
  }
  getNotAcknoledgeOrders(){
    console.warn('runNotAcknowledgeOrders',this.storeID );
    return this.http.get<any>(this.env.API_URL + 'user-not-acknowledge-orders/' + this.storeID,{}).pipe(
      // tap(response => {
      //   console.warn('total not ack', response);
      // }),
      retry(2),
      catchError(this.handleError)
    )
  }

  getToken() {

    return this.storageService.get('token').then(
      data => {

        if (data){
          this.token = data;
          this.token = this.token;

          this.user().subscribe(
            user => {
              // console.log('this.user()',user);
              this.userRole = user.user_role;

              
        
              // this.isLoggedIn=true;
              // this.userID = data.id;
              // this.userEmail = data.email;
              // this.alertService.presentToast('Welcome back ' + data.name + '!','success');
            },
            error => {
              // this.storageService.remove("token");
              this.isLoggedIn = false;
            },
            () => {
              // this.dismissLogin();
              // this.navCtrl.navigateRoot('/landing');
            }
          );
        }else{
          console.warn('No token found');
        }

        

      },
      error => {
        this.token = null;
        this.isLoggedIn=false;
      }
    );
  }


  updateUser(formData: FormData) {

    console.log('updateStore formData',formData);
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.post<any>(this.env.API_URL + 'user/update-user',formData,{ headers: headers }).pipe(
      tap(user => {
        console.log('updateUser',user);
        return user;
      }),
      retry(2),
      catchError(this.handleError)
    )
  }

  updateUserLocation() {

    // console.log('updateUserLocation');
    
    let payload = {};
    this.storageService.get('coordinates').then(
      data => {
        if (data){
          const coors = JSON.parse(data);

          // console.log('coors',coors);
          let latitude = coors.latitude;
          let longitude = coors.longitude;
          let geolocation = coors.address;
          let address = coors.addressInformation;

          localStorage.setItem('latitude',latitude);
          localStorage.setItem('longitude',longitude);
          localStorage.setItem('geolocation',geolocation);
          localStorage.setItem('address',address);

          // alert(address);

          payload = {latitude,longitude,geolocation,address};
          
          // alert(JSON.stringify(payload));
          // alert(this.token);
          // this.user();
          if (this.isLoggedIn){
            this.runUpdateUser(payload).subscribe(data => {
              // console.log('runUpdateUser data',data);
            });
          }
          

        }
      }
    );

    
  }

  runUpdateUser(payload): Observable<any> {

          const headers = new HttpHeaders({
            'Authorization': "Bearer " + this.token
          });

          
          return this.http.post<any>(this.env.API_URL + 'user/update-location',payload,{ headers: headers }).pipe(
            retry(2),
            catchError(this.handleError)
          )

  }

  resetPassword(payload: any) {

    return this.http.post<any>(this.env.API_URL + 'reset-password',payload,{})
    .pipe(
      tap(result => {
        //
      }),
      retry(2),
      catchError(this.handleError)
    );
  }


  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  getUsers(): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.get<any>(this.env.API_URL + 'users',{ headers: headers })
      .pipe(
        tap(result => {
          this.users = result.data.data;
        }),
        retry(2),
        catchError(this.handleError)
      )
  }

  getUsersByRole(role: string = 'customer'): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.get<any>(this.env.API_URL + 'users?role=' + role,{ headers: headers })
      .pipe(
        tap(result => {
          this.cargoManagers = result.data.data;
        }),
        retry(2),
        catchError(this.handleError)
      )
  }

  getUser(userID) {
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.get(this.env.API_URL + 'users/' + userID,{ headers: headers }).pipe(
      tap(data => {
        console.log('updateStore',data);
        return data;
      })
    );
    
  }

  updateUserByID(userID,formData: FormData) {

    // console.log('updateStore formData',formData);
    
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token
    });

    return this.http.post<any>(this.env.API_URL + 'users/' + userID,formData,{ headers: headers }).pipe(
      tap(user => {
        console.log('updateUser',user);
        return user;
      }),
      retry(2),
      catchError(this.handleError)
    )
  }

  deleteUser(userID) {

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.token,
    });

    return this.http.delete(this.env.API_URL + 'users/' + userID,{ headers: headers }).pipe(
      tap(data => {
        console.log('delete user',data);
        return data;
      })
    )
  }

}