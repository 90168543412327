import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'src/app/models/store';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-store-slider-detail',
  templateUrl: './store-slider-detail.component.html',
  styleUrls: ['./store-slider-detail.component.scss'],
})
export class StoreSliderDetailComponent implements OnInit {

  @Input() store?: Store;
  constructor(
    private router: Router
  ) {
    
  }

  businessHours: any = null;
  dubaiTime: string;
  storeBusinessStatus: string = 'Checking';

  ngOnInit() {

    if (this.store.store_business_hours){
      this.businessHours = this.store.store_business_hours;
      // console.log('this.store_name',this.store.store_name);
      // console.log('this.businessHours',this.store);
      this.checkStoreStatus();
    }

    // this.dubaiTime = moment.tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss');

    // if (!this.store.store_business_hours){
    //   this.businessHours = this.store.store_business_hours;
    //   console.log('this.businessHours',this.businessHours);
    //   // this.checkStoreStatus();
    // }

  }
  ngAfterViewInit() {

   

  }

  navigate(){
    let storeID = this.store.store_id;
    if (!this.store.store_id){
      storeID = this.store.id.toString();
      
    }
    

    this.router.navigate(['/store', storeID]);
  }

  checkStoreStatus() {

    // console.log('checkStoreStatus');
    // Get current day and time in Dubai
    const now = moment.tz('Asia/Dubai');
    const currentDay = now.format('dddd'); // Get the current day (e.g., 'Monday')
    const currentTime = now.format('HH:mm'); // Get the current time (e.g., '14:30')

    // Get store hours for the current day
  const storeSchedule = this.businessHours[currentDay];

    // console.log('currentDay',currentDay);
    // console.log('currentTime',currentTime);
    // console.log('storeSchedule',storeSchedule);

    // Check if the store is closed for the entire day
    if (storeSchedule.openingTime === 'Closed') {
      this.storeBusinessStatus = 'Closed';
    } else {
      // Check if the current time is within the opening and closing hours
      if (currentTime >= storeSchedule.openingTime && currentTime <= storeSchedule.closingTime) {
        this.storeBusinessStatus = 'Open';
      } else {

        if (currentTime < storeSchedule.openingTime){
          this.storeBusinessStatus = 'Opening Soon';
        }
        if (currentTime > storeSchedule.openingTime){
          this.storeBusinessStatus = 'Closed';
        }
        
      }
    }
  }
}
